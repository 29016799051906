import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Header from "../components/Header"
import Footer from "../components/Footer"
import InfoCard from "../components/InfoCard"

const Projects = ({ data }) => {
  return (
    <div className="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="author" content="Alp Kahvecioglu"></meta>
        <meta
          name="description"
          content="showcase for projects created by alp kahvecioglu"
        ></meta>
        <title>alp kahvecioglu -- projects</title>
        <link rel="canonical" href="https://enpassant.me/projects" />
        <html lang="en" />
      </Helmet>
      <Header />
      <p>
        Here you can find some of my previous work. For smaller things you can
        visit{" "}
        <a
          style={{ textDecoration: "underline", color: "#551a8b" }}
          href="https://github.com/dyslexicat"
        >
          my GitHub
        </a>
        .{" "}
      </p>
      <p>
        Current number of projects I have worked on is:{" "}
        {data.site.siteMetadata.projects.length}{" "}
      </p>
      <div style={infocardStyles}>
        {data.site.siteMetadata.projects.map(project => {
          return (
            <InfoCard
              key={project.name}
              name={project.name}
              tech={project.tech}
              link={project.link}
              info={project.info}
            />
          )
        })}
      </div>
      <Footer />
    </div>
  )
}

const infocardStyles = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr))",
  gridColumnGap: "20px",
  marginBottom: "2rem",
}

export const query = graphql`
  query projectsQuery {
    site {
      siteMetadata {
        projects {
          name
          tech
          link
          info
        }
      }
    }
  }
`

export default Projects
