import React from "react"
import { v4 as uuidv4 } from 'uuid';

const TechStack = ({ names }) => {
  return (
    <div className="tech">
      {names.map(name => (
        <span key={uuidv4()}>{name}</span>
      ))}
    </div>
  )
}

export default TechStack
