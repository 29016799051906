import React from "react"
import TechStack from "./TechStack"

const InfoCard = ({ name, tech, link, info }) => {
  return (
    <div className="card">
      <a href={link}>
        <div className="project-name">{name}</div>
      </a>
      <TechStack names={tech} />
      <p>{info}</p>
    </div>
  )
}

export default InfoCard
